/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import type { ApolloError } from 'apollo-client';
import { useQuery } from 'react-apollo';

import { getIsProductOrSiteAdminFromQuery } from '@confluence/current-user';

import type {
	indexAutomationAdminQuery as indexAutomationAdminQuery$data,
	indexAutomationAdminQueryVariables as indexAutomationAdminQuery$variables,
} from './__types__/indexAutomationAdminQuery';

export type AutomationPermissionValue = {
	canManageAutomations: boolean;
	error?: ApolloError;
};

export const useAutomationPermissionService = (spaceKey: string): AutomationPermissionValue => {
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	const skipQuery = !spaceKey || Boolean(process.env.REACT_SSR); // indexAutomationAdminQuery isn't preloaded and can be skipped during SSR to avoid unnecessary requests
	const { data, error } = useQuery<
		indexAutomationAdminQuery$data,
		indexAutomationAdminQuery$variables
	>(
		gql`
			query indexAutomationAdminQuery($spaceKey: String!) {
				# indexAutomationAdminQuery isn't preloaded and can be skipped during SSR to avoid unnecessary requests
				user(current: true) {
					id
					confluence {
						operations {
							operation
							targetType
						}
					}
				}
				isSiteAdmin
				hasUserAccessAdminRole
				space(key: $spaceKey) {
					id
					currentUser {
						isAdmin
					}
				}
			}
		`,
		{
			variables: {
				spaceKey: spaceKey!,
			},
			skip: skipQuery,
		},
	);

	const isSpaceAdmin = data?.space?.currentUser?.isAdmin ?? false;
	const isProductOrSiteAdmin = getIsProductOrSiteAdminFromQuery(data);

	return {
		canManageAutomations: isSpaceAdmin || isProductOrSiteAdmin,
		error,
	};
};
