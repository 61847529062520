import { fg } from '@confluence/feature-gating';

export type Environment = 'prod' | 'pre-prod' | 'staging' | 'dev' | 'local';

export const mapEnv = (env: 'DEVELOPMENT' | 'PRODUCTION' | 'STAGING'): Environment => {
	switch (env) {
		case 'DEVELOPMENT':
			return 'dev';
		case 'PRODUCTION':
			return fg('automation-dev-helper') ? 'pre-prod' : 'prod';
		case 'STAGING':
			return 'staging';
		default:
			return 'local';
	}
};
